<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex jc-between" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex">
					<el-col style="flex: 2.5;">
						<el-form-item label="报警状态：">
							<el-select v-model="searchParm.status" clearable :popper-append-to-body="false"
								@change="changeStatus" placeholder="请选择">
								<el-option v-for="item in status" :key="item.name" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col style="flex: 3;">
						<el-input :value="searchParm.keyword" @input="handleChange" style="min-width: 300px;"
							placeholder="请输入关键字搜索">
							<i class="el-input__icon el-icon-search" slot="suffix"></i>
						</el-input>
					</el-col>
					<el-col style="flex: 4;">
						<el-form-item label="报警日期：">
							<el-col :span="10">
								<el-date-picker v-model="searchParm.startTime" value-format="yyyy-MM-dd hh:mm:ss"
									type="datetime" placeholder="请选择日期"></el-date-picker>
							</el-col>
							<el-col :span="2" style="text-align: center;"><span>-</span></el-col>
							<el-col :span="10">
								<el-date-picker v-model="searchParm.endTime" value-format="yyyy-MM-dd hh:mm:ss"
									type="datetime" placeholder="请选择日期"></el-date-picker>
							</el-col>
						</el-form-item>
					</el-col>
				</el-row>
				<div style="width: 200px;text-align: right;">
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>报警列表</span>
				</div>
				<div class="title-right">
					<el-button v-hasPerm="'1554304130363822080'" type="primary" size="medium" style="margin-right: 8px;"
						@click="download()">下载excel</el-button>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;" v-loading="isLoading">
							>
							<template slot="empty">
								<el-empty :image-size="100" description="暂无数据"></el-empty>
							</template>
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<!-- <el-table-column prop="entName" align="center" label="企业简称" show-overflow-tooltip></el-table-column> -->
							<el-table-column align="center" label="设备名称" show-overflow-tooltip>
								<template slot-scope="scope">
									<div style="cursor:pointer;" @click="getDchats(scope.row)">{{ scope.row.unitName }}
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="lineName" align="center" label="归属产线"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="userName" align="center" label="包机人"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="userTel" align="center" label="联系电话"
								show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="启停状态">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">
										{{ scope.row.haltStatus == 0 ? '开机' : '停机' }}</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="报警等级">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">{{ alarm[scope.row.alarmLevel] }}</div>
								</template>
							</el-table-column>
							<!-- <el-table-column prop="alarmLevel" align="center" label="报警等级" show-overflow-tooltip></el-table-column> -->
							<el-table-column align="center" label="数据来源">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">{{ types[scope.row.source] }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="alarmTime" align="center" label="报警时间"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="remark" align="center" label="备注"
								show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="报警状态">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">
										<el-button type="text">{{ statusList[scope.row.status]}}</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total"></el-pagination>
				</div>
			</div>
		</div>
		<threeDchats ref="threeDchats" :row="thRow"></threeDchats>
	</div>
</template>
<script>
	import caches from '@cache/cache.js';
	import smCard from '@c/smCard.vue';
	import threeDchats from '../../monitoring/component/threeDchats.vue';
	export default {
		components: {
			smCard,
			threeDchats
		},
		data() {
			return {
				thRow: null,
				tableHeight: 0,
				tableData: {
					records: []
				},
				searchParm: {
					size: 20,
					current: 1,
					keyword: ''
				},
				ents: [],
				types: ['振动', '转速', '温度', '油液', 'wincc'],
				alarm: ['正常', '一级报警', '二级报警', '三级报警'],
				status: [{
						name: '未解除',
						id: 2
					},
					{
						name: '已解除',
						id: 3
					},
					{
						name: '全部',
						id: 0
					}
				],
				statusList: {
					2: '未解除',
					21: '未解除',
					3: '已解除',
					31: '已解除'
				},
				isLoading: false
			};
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect();
					this.tableHeight = rect.height;
				});
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 20,
					current: 1,
					keyword: ''
				};
			},
			handleChange(value) {
				this.searchParm.keyword = value;
			},
			//展示数据
			list() {
				//请求之前，开启loading
				this.isLoading = true;
				this.$get('/backend-api/alarm/unit/list', this.searchParm)
					.then(res => {
						this.isLoading = false;
						if (res.code == 1000) {
							this.tableData = res.data;
						} else {
							this.$message.error(res.msg);
						}
					})
					.catch(err => {
						//请求失败，关闭loading
						this.isLoading = false;
					});
			},

			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			getDchats(item) {
				if (item.typeId && item.typeId != '') {
					let fileUrl = ''
					if (item.isType == 1) {
						fileUrl = '/html/index.html'
					} else {
						fileUrl = '/html/indexOld.html'
					}

					var url = {
						url: fileUrl + '?token=' + caches.token.get() +
							'&unitId=' +
							item.unitId +
							'&typeId=' +
							item.typeId +
							'&url=' +
							this.baseURL +
							'&imgUrl=' +
							this.photoURL
					};
					this.$refs.threeDchats.dialogVisible = true;
					this.$refs.threeDchats.fullscreen = true;
					this.thRow = Object.assign(item, url);
				} else {
					this.$message.warning('3D模型制作中');
				}
			},
			download() {
				let url = this.baseURL + '/backend-api/alarm/unit/download?ent_id=' + caches.user.get().entId;
				if (this.searchParm.endTime) {
					url += '&endTime=' + this.searchParm.endTime
				}
				if (this.searchParm.keyword) {
					url += '&keyword=' + this.searchParm.keyword
				}
				if (this.searchParm.startTime) {
					url += '&startTime=' + this.searchParm.startTime
				}
				if (this.searchParm.status) {
					url += '&status=' + this.searchParm.status
				}
				// //访问路径直接下载
				window.location.href = url;
			},
			changeStatus(e) {
				this.$nextTick(() => {
					this.searchParm.status = e;
					this.list();
				})
			}
		},
		mounted() {
			this.calHeight();
			window.addEventListener('resize', this.calHeight);
			if (this.$route.params.alarmStatus) {
				this.searchParm.keyword = this.$route.params.alarmStatus;
				this.list();
			} else {
				this.searchParm.status = 0;
				this.list();
			}
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight);
		}
	};
</script>
<style lang="scss" scoped>
	@import '@style/userCenter.scss';
</style>